

.card-body p span {
    font-family: Montserrat
}

.card-text {
    font-family: Montserrat
}

.top-time {
    font-family: Montserrat    
}

@media (max-width: 575px) {
    /* .product-card .card-style {
        width: 80%;
        margin: auto;
        text-align: center;
    }

    .scroll-hotel {
        background:red;
        padding:0;
        margin:0
    }

    .product-card .card-style img {
        max-width: 100%;
        max-height: 100vh;
        margin: auto;
    }

    .card-style .card-body {
        height: 10vh;
    }

    .card-text {
        width: 100%;
        margin: auto;
    } */
}


@media (max-width: 991px) {
    .product-card .card-style {
        margin: auto;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
    /* .card-style img {
        width: 300px;
        height: 20vh
    } */
    .product-inner-card {
        margin: 0 auto
    }
}

@media (min-width: 992px) {
    .product-card .card-style img {
        max-width: 100%;
        max-height: 100vh;
        margin: auto;
    }
    .product-card .card-style {
        width: 18rem;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
    .card-style img {
        width: 300px;
        height: 20vh
    }
}