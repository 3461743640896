.dropdown-menu:li {
  text-align: left;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.overview {
  display: flex;
  position: relative;
  top: 27%;
}

.overview2 {
  padding-left: 18rem;
  position: relative;
  bottom: 20px;
  left: 29%;
}

@media (max-width: 991px) {
  .main-nav {
    padding: 0% !important;
  }
  .nav-1 {
    width: 100%;
  }
  .nav-2 {
    width: 100%;
  }
}

.nav-menu {
  position: relative;
  text-align: center;
  float: none;
}

.nav-menu > li {
  float: none;
  display: inline-block;
}

.nav-menu > li > a {
  display: block;
  padding: 25px 15px;
  line-height: 20px;
  font-weight: 650;
}

/*----------------------------*\
	dropdown
\*----------------------------*/

.nav-menu li.has-dropdown {
  position: relative;
}

.nav-menu li.has-dropdown > a:after {
  font-family: "FontAwesome";
  content: "\f0d7";
  margin-left: 5px;
}

.nav-menu li.has-dropdown > .dropdown {
  position: absolute;
  left: 0;
  width: 200px;
  text-align: left;
  z-index: 60;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all;
  transition: 0.7s all;
}

.nav-menu li.has-dropdown:hover > .dropdown {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.nav-menu li.has-dropdown > .dropdown .dropdown-body {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-top: 2px solid #ee4266;
}

.dropdown .dropdown-list li {
  border-bottom: 1px solid #e8eaed;
}

.dropdown .dropdown-list li a {
  display: block;
  padding: 10px;
  line-height: 20px;
}

.dropdown .dropdown-heading {
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 30px;
}

/*----------------------------*\
	mega dropdown
\*----------------------------*/

.nav-menu li.has-dropdown.megamenu {
  position: static;
}

.nav-menu li.has-dropdown.megamenu > .dropdown {
  width: 100%;
}

.nav-menu li.has-dropdown.megamenu > .dropdown .dropdown-body {
  padding: 30px;
}

.nav-menu li.has-dropdown.megamenu > .dropdown .dropdown-list {
  margin-bottom: 30px;
}

.nav-menu li.has-dropdown.megamenu > .dropdown .dropdown-list li a {
  padding-left: 0px;
  padding-right: 0px;
}

/*----------------------------*\
	tab dropdown
\*----------------------------*/

.nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-content {
  z-index: 70;
  position: relative;
  margin-left: -30px;
}

.nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-nav {
  background-color: #1b1c1e;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 60;
}

.nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-nav li {
  border-bottom: 1px solid #323335;
}

.nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-nav li a {
  display: block;
  padding: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.nav-menu li.has-dropdown.megamenu > .dropdown.tab-dropdown .tab-nav li.active {
  background: #ee4266;
}

.nav-menu
  li.has-dropdown.megamenu
  > .dropdown.tab-dropdown
  .tab-nav
  li.active
  a {
  color: #fff;
}

/*----------------------------*\
	nav aside
\*----------------------------*/

.nav-aside {
  position: fixed;
  right: 50%;
  top: 0;
  bottom: 0;
  background-color: #1d1d1f;
  max-width: 100rem;
  width: 100%;
  padding: 80px 20px;
  overflow-y: scroll;
  z-index: 99;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 0.4s all cubic-bezier(0.77, 0, 0.18, 1);
  transition: 0.4s all cubic-bezier(0.77, 0, 0.18, 1);
}

@media (max-width: 779px) {
  .header-scrolled {
    display: none !important;
  }
}
@media (max-width: 730px) {
  .nav-aside {
    position: fixed;
    right: 61%;
    top: 0;
    bottom: 0;
    background-color: #1b1c1e;
    max-width: 200rem;
    width: 100%;
    padding: 80px 20px;
    overflow-y: scroll;
    z-index: 99;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: 0.4s all cubic-bezier(0.77, 0, 0.18, 1);
    transition: 0.4s all cubic-bezier(0.77, 0, 0.18, 1);
  }
}
.nav-none {
  display: none;
}
.nav-btns {
  float: right;
  padding: 10px 0px;
}

.nav-btns > button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: none;
  background: transparent;
}

.nav-aside.active {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.nav-aside-menu li a {
  display: block;
  padding: 15px 0px;
  color: #ffffff !important;
  font-weight: 700;
  font-family: 'Raleway' , sans-serif ;
  border-bottom: 1px solid #323335;
}

.nav-aside-menu li a:hover,
.nav-aside-menu li a:focus {
  color: #e6c507 !important;
}

.nav-aside-menu li.has-dropdown > a {
  cursor: pointer;
}

.nav-aside-menu li.has-dropdown > a:after {
  font-family: "FontAwesome";
  content: "\f0d7";
  float: right;
}

.nav-aside-menu li.has-dropdown > .dropdown {
  display: none;
  margin-left: 30px;
  border-left: 1px solid #323335;
}

.nav-aside-menu li.has-dropdown.active > .dropdown {
  display: block;
}

.nav-aside-menu li.has-dropdown > .dropdown a {
  padding: 15px;
}

.nav-close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  left: 39%;
  /* right: 30%; */
  background-color: transparent;
  border: none;
}

.nav-close span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nav-close span:before,
.nav-close span:after {
  content: "";
  display: block;
  width: 30px;
  background-color: #fff;
  height: 2px;
}

.nav-close span:before {
  -webkit-transform: translateY(0px) rotate(-135deg);
  -ms-transform: translateY(0px) rotate(-135deg);
  transform: translateY(0px) rotate(-135deg);
}

.nav-close span:after {
  -webkit-transform: translateY(-2px) rotate(135deg);
  -ms-transform: translateY(-2px) rotate(135deg);
  transform: translateY(-2px) rotate(135deg);
}

.navbar-custom {
  background: white!important;
  color: white;
}

.navbar-text {
  color: white;
}

/* .nav-aside-menu li a{
  color: #fff !important;
  font-weight: 700;
} */

.navbar-nav li a {
  color: rgb(43, 39, 39) !important;
  font-weight: 660;
}

.nav-scrolled {
  position: sticky;
  top: 0;
  z-index: 12345;
}

 .custom-toggler .navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(128, 0, 128, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
 }