body {
    background : linear-gradient(60 ,rgba(155, 150, 150, 0.5) , rgba(242,242,242, 0.8))
    contain
    center
}

main {
    margin : 4px 16px;
    padding: 8px;
    
}

.Header {
    font-size : 2.0rem;
    font-weight: 670;
}

.textbox {
    padding : 8px 16px;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

.textbox p {
    font-size: 1.15em;
    line-height: 1.2;
    font-weight: 450;
}

@media only screen and (max-width: 768px) {
    main {
        margin: 4px 8px;
        padding: 12px;
    }

    .Header {
        font-size: 1.3rem;
        font-weight: 620;
    }

    .textbox {
        width: 100%;
        padding : 8px 12px;
 }

    .textbox p {
        font-size : 1.23em;
        font-weight: 470;
        line-height: 1.2;
    }

}