@media (max-width: 997px) {
    .properties-btn {
      padding-left: 50px;
      padding-top: 20px;
    }
  
    .agent-form .w-100 {
      width: 100% !important;
    }
  
    .agent-form .form-label {
      margin-bottom: 0.5rem;
      width: 328px;
      overflow-x: hidden !important;
    }
  
    .agent-form {
      width: 100% !important;
      margin: 0;
      overflow-x: hidden !important;
      /* padding-right: 285px !important; */
    }
    .agent-form .form-select {
      width: 100%;
      overflow-x: hidden !important;
    }
    .agent-properties {
      width: 300px !important;
      overflow-x: hidden !important;
      margin: auto !important;
    }
    
    .agent-main {
      padding-left: 35px !important;
      overflow-x: hidden !important;
    }
  }