
@media (min-width: 992px) {
    .footer-section {
        background: #f5f5f5;
        width: 100%
    }

    .top-text {
        padding-top: 100px
    }

    .wb_Text9 {
        background-color: transparent;
        background-image: none;
        border: 0px solid #000000;
        border-radius: 0px;
        padding: 0px 0px 40px 0px;
        font-family: Montserrat;
        margin: 0 auto;
        text-align: center;
        width: 50%;
        line-height: 24px;
        font-size: 15px;
    }

    .download-app{
        text-align: center;
        margin: 0 auto;
    }

    .image10 {
        border: 0px solid #000000;
        border-radius: 0px;
        box-sizing: border-box;
        text-align: center;
        padding: 0;
        display: block;
        width: 150px;
        height: auto;
        max-width: 701px;
        position: relative;
        cursor: pointer
    }

    .column {
        display:inline-block;
        height:auto;
        z-index:64;
        /* float: left; */
        width: 150px;
    }
    
    /* Clear floats after image containers */
    .download-app::after {
        content: "";
        clear: both;
        display: table;
    }

    .layout-4 {
        float: none;
        text-align: center;
        width: 100% !important;
        margin-top: 20px;
    }

    .layout-4 i {
        color: #800080;
        display: inline-block;
        font-size: 37px;
        line-height: 37px;
        vertical-align: middle;
        width: 36px;
    }

    .layout-4 i:hover {
        color: yellow;
        cursor: pointer
    }

    .image12 {
        margin-right: 40px;
        width: 173px;
        height: 39px
    }

    .layout-5 {
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        background-color: #F5F5F5;
        background-image: none;
        border: 0px solid #CCCCCC;
        box-sizing: border-box;
        margin-top: 15%
    }

    .image13 {
        width: 616px;
        height: 130px
    }

    

    .footer-banner img {
        width: 100%
    }

}

@media (max-width: 991px) {
    .footer-section {
        background: #f5f5f5
    }

    .top-text {
        padding-top: 100px
    }

    .wb_Text9 {
        background-color: transparent;
        background-image: none;
        border: 0px solid #000000;
        border-radius: 0px;
        padding: 0px 0px 40px 0px;
        margin: 0 auto;
        text-align: center;
        width: 80%;
    }

    .download-app{
        text-align: center;
        margin: 0 auto;
    }

    .image10 {
        border: 0px solid #000000;
        border-radius: 0px;
        box-sizing: border-box;
        text-align: center;
        padding: 0;
        display: block;
        width: 100%;
        height: auto;
        max-width: 701px;
        position: relative;
    }

    .column {
        display:inline-block;
        height:auto;
        z-index:64;
        /* float: left; */
        width: 200px;
        margin-bottom: 20px
    }
    
    /* Clear floats after image containers */
    .download-app::after {
        content: "";
        clear: both;
        display: table;
    }

    .layout-4 {
        float: none;
        text-align: center;
        width: 100% !important;
    }

    .layout-4 i {
        color: #800080;
        display: inline-block;
        font-size: 37px;
        line-height: 37px;
        vertical-align: middle;
        width: 36px;
    }

    .layout-4 i:hover {
        color: yellow;
        cursor: pointer
    }

    .image12 {
        margin-right: 20px;
        width: 103px;
        height: 29px
    }

    .layout-5 {
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        background-color: #F5F5F5;
        background-image: none;
        border: 0px solid #CCCCCC;
        box-sizing: border-box;
        margin-top: 15%
    }

    .image13 {
        width: 100%;
        height: 130px
    }
}


/* global footer styles */
.footer-banner {
    padding-top: 2%
}

.footer-banner img {
    width: 100%
}