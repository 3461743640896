body {
  overflow-y: scroll !important;
}

@media (min-width: 601px) {
  .mobile-logo {
    display: none;
  }
}

.price {
  font-weight: 600;
  font-size: 17px;
}

.card-details {
  font-size: 14px;
}

.card-address {
  font-size: 12px;
}

.category-options {
  background-color: rgb(249, 249, 251);
  border: 1px solid rgb(209, 209, 213);
  border-radius: 24px;
  box-sizing: border-box;
  display: inline-block;
  height: 36px;
  position: relative;
}

.category-options .active-option {
  background-color: rgb(0, 106, 255);
  color: rgb(255, 255, 255);
  height: 36px;
  margin-top: 0px;
}
.category-options .left-option {
  left: -100px;
}

/* .category-options .left-option {
  left: -100px;
}
.category-options .right-option {
  right: 0px;
}
.category-options .right-end-option {
  left: 70px;
} */

.category-options .option {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(249, 249, 251);
  border: none;
  border-radius: 24px;
  color: rgb(42, 42, 51);
  display: flex;
  font-size: 14px;
  height: 34px;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 19px;
  margin-top: 1px;
  outline: 0px;
  padding: 7px 0px;
  position: absolute;
  text-align: center;
  top: -1px;
  user-select: none;
  white-space: pre;
  width: 49%;
}

@media (min-width: 1069px) {
  .product-card .card {
    width: 100% !important;
    /* height: 300px; */
  }

  .product-card .card img {
    width: 100%;
    height: 200px;
  }
}

.hide {
  display: none;
}
.myDIV {
  background: #333;
  border-radius: 50%;
  font-size: 10px;

  color: #fff;
}

.myDIV:hover + .hide {
  position: relative;
  display: block;
  color: red;
  width: 50%;
  background: #ccc;
  color: #fff;
}

/* \desktop screen */
@media (min-width: 992px) {
  /*.das-paragraph {
    width: 80%;
  }*/

  .scrollspy-example {
    position: relative;
    height: 300px;
    overflow: auto;
  }
  .category-options {
    width: 50%;
    float: right;
  }
  .category-options .right-option {
    right: -24px;
  }
  .category-options .right-end-option {
    left: 70px;
  }
  .sort-section {
    float: right;
  }

  .sort-text {
    width: 40%;
  }

  /* .buy-top {
    margin-bottom: 3%;
  } */
  .mt-5s {
    margin-top: 0.5%;
  }

  /* .top-house {
    bottom: 75%;
  }
  
  .top-house .top-time {
    background-color: rgba(51, 49, 49, 0.479);
    padding: 5px;
  }
  
   .top-house .top-icon {
    font-size: 26px;
    color: #fff;
    position: absolute;
    float: right;
  } */

  .overwrite-dropdown {
    width: 500px;
  }
  .overwrite-dropdown2 {
    width: 300px;
  }
  /* PrivateValueLabel-circle-4" */

  .subs-nav li a {
    text-decoration: none;
    color: #777;
    font-size: 12px;
    font-family: Montserrat;
  }
  .card-style {
    border-bottom: 3px solid #800080 !important;
  }

  .btns button {
    font-size: 10px;
    font-family: Montserrat;
  }
  .btns .seach-icon {
    position: relative;
    right: 36%;
    top: 25%;
    /* left: 6%; */
    /* top: 23%; */
    /* right: 5%; */
  }

  .scroll-hotel {
    overflow-x: hidden;
  }

  .btns .sale-icon {
    position: relative;
    top: 9%;
    /* right: 39% */
    left: 20%;
  }

  .buy-button {
    position: relative;
  }

  .btn-botton1 {
    position: relative;
    left: 4%;
  }

  .buy-button .btn-botton2 {
    width: 30%;
  }

  .main-dropdown {
    list-style: none;
  }

  .main-dropdown .marquee-link {
    text-decoration: none;
  }

  /* start */
  /* .buy-cat-button {
      cursor: pointer;
      font-weight: normal;
      font-style: normal;
      font-family: Montserrat;
      margin: 0 10px 0 0;
      outline: 0;
      padding: 8px 20px 9px 15px;
      position: relative;
      text-align: center;
      text-decoration: none;
      color: #4F4F4F;
  } */
  /* .buy-cat-button {
      background: "purple";
      color: "#fff";
       fontSize: "12px"
  } */

  .ui-state-default {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #f5f5f5;
    color: #4f4f4f;
  }

  .ui-state-default:hover {
    border: 1px solid #800080;
    transition: 0.4s;
  }

  /* 
  .sort {
      cursor: pointer;
      font-weight: normal;
      font-style: normal;
      margin: 0 10px 0 0;
      outline: 0;
      padding: 8px 7px 9px 7px;
      position: relative;
      text-decoration: none;
      color: #4F4F4F;
  } */

  .top-house .top-time {
    /* float: left; */
    background: #333;
    position: absolute;
    padding: 0 5px;
    left: 65%;
    top: 0;
    border-radius: 10px;
  }
}

#nav {
  list-style: none inside;
  margin: 0;
  padding: 0;
  text-align: center;
}
#nav li {
  display: block;
  position: relative;
  float: left;
  background: #24af15; /* menu background color */
}
#nav li a {
  display: block;
  padding: 0;
  text-decoration: none;
  width: 200px; /* this is the width of the menu items */
  line-height: 35px; /* this is the hieght of the menu items */
  color: #800080; /* list item font color */
}
#nav li li a {
  font-size: 80%;
} /* smaller font size for sub menu items */
#nav li:hover {
  background: #003f20;
} /* highlights current hovered list item and the parent list items when hovering over sub menues */
#nav ul {
  position: absolute;
  padding: 0;
  left: 0;
  display: none; /* hides sublists */
}
#nav li:hover ul ul {
  display: none;
} /* hides sub-sublists */
#nav li:hover ul {
  display: block;
} /* shows sublist on hover */
#nav li li:hover ul {
  display: block; /* shows sub-sublist on hover */
  margin-left: 200px; /* this should be the same width as the parent list item */
  margin-top: -35px; /* aligns top of sub menu with top of list item */
}

/* global */
input[type="checkbox"],
input[type="radio"] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

input[type="radio"] {
  bottom: 2px;
}

.sort-list select {
  font-family: Montserrat;
}

.sort-list p {
  float: left;
  font-family: Montserrat;
}

.dropdown {
  font-family: Montserrat;
}

.product-inner-card {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.nav-aside-menu li {
  list-style-type: none;
}

.more-mobile li {
  list-style-type: none;
}

.modal-icon {
  margin-right: 10px;
  color: #800080;
  cursor: pointer;
}

.modal-icons {
  color: #800080;
}

.top-house {
  position: absolute;
  /* z-index: 999; */
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 5%;
  padding-right: 20px;
}

.top-house .top-icon {
  float: right;
  color: #fff;
  font-size: 19px;
}

/* .top-icon:hover {
  background: red
} */

.fa-heart-o:active {
  color: #800080;
}

/* .top-house {
  position: absolute;
  bottom: 75%;
}

.top-house .top-time {
  background-color: rgba(51, 49, 49, 0.479);
  padding: 5px;
} */

.overwrite-dropdown {
  width: 500px;
}
.overwrite-dropdown2 {
  width: 300px;
}

.subs-nav li a {
  text-decoration: none;
  color: #777;
  font-size: 12px;
}
.card-style {
  border-bottom: 3px solid #800080 !important;
}

.btns button {
  font-size: 10px;
}
.btns .seach-icon {
  position: relative;
  right: 36%;
  top: 25%;
  /* left: 6%; */
  /* top: 23%; */
  /* right: 5%; */
}

.btns .sale-icon {
  position: relative;
  top: 9%;
  /* right: 39% */
  left: 20%;
}

.buy-button {
  position: relative;
}

.btn-botton1 {
  position: relative;
  left: 4%;
}

.buy-button .btn-botton2 {
  width: 30%;
}

.main-dropdown {
  list-style: none;
}

.main-dropdown .marquee-link {
  text-decoration: none;
}

.small-screen {
  display: none;
}

@media (max-width: 991px) {
  .mt-5s {
    margin-top: 0.5%;
  }
  .category-options {
    width: 60%;
    float: right;
  }
  .category-options .right-option {
    right: 0px;
  }
  .category-options .right-end-option {
    left: 40px;
  }
  .sort-section {
    float: right;
  }
  .sort-text {
    width: 50%;
  }
  .card-body {
    text-align: left;
  }

  .top-house .top-time {
    margin: auto;
    background: #333;
    position: absolute;
    padding: 0 5px;
    left: 70%;
    top: 0;
    border-radius: 10px;
  }

  .buy-search-top {
    width: 500px;
    margin-bottom: 2%;
  }
  .buy-search-top input {
    border: 2px solid #800080;
  }

  .buttons-horizontal {
    margin-bottom: 5%;
  }

  /* .buy-cat-button {
    cursor: pointer;
    font-weight: normal;
    font-style: normal;
    outline: 0;
    padding: 4px 10px 5px 10px;
    font-family: Montserrat;
    position: relative;
    text-align: center;
    text-decoration: none;
    color: #4f4f4f;
  } */

  .ui-state-default {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #f5f5f5;
    color: #4f4f4f;
  }

  .top-buttons-sections {
    /* background: green */
  }

  .top-buttons-sections form {
    width: 100px;
  }

  .single-button {
  }

  .top-buttons-sections .main-dropdown {
    width: 100%;
  }

  .map-section {
    display: none;
  }
  .map-top {
    text-align: center;
    margin: auto;
  }
  .scroll-hotel {
    text-align: center;
    margin: 0 auto;
  }
  .small-screen {
    display: block;
  }
  .big-screen {
    display: none;
  }

  /* .top-house .top-icon {
    font-size: 26px;
    color: #fff;
    position: absolute;
    left: 15rem;
    top: 25%;
  } */
}

@media (min-width: 992px) {
  .buy-search-top input {
    border: 2px solid #800080;
  }

  /* .top-house {
    position: absolute;
    bottom: 75%;
  }
  
  .top-house .top-time {
    background-color: rgba(51, 49, 49, 0.479);
    padding: 5px;
  }
  
  .top-house .top-icon {
    font-size: 26px;
    color: #fff;
    position: absolute;
    left: 15rem;
    top: 25%;
  } */

  .overwrite-dropdown {
    width: 500px;
  }
  .overwrite-dropdown2 {
    width: 300px;
  }

  .subs-nav li a {
    text-decoration: none;
    color: #777;
    font-size: 12px;
    font-family: Montserrat;
  }
  .card-style {
    border-bottom: 3px solid #800080 !important;
  }

  .btns button {
    font-size: 10px;
    font-family: Montserrat;
  }
  .btns .seach-icon {
    position: relative;
    right: 36%;
    top: 25%;
    /* left: 6%; */
    /* top: 23%; */
    /* right: 5%; */
  }

  .btns .sale-icon {
    position: relative;
    top: 9%;
    /* right: 39% */
    left: 20%;
  }

  .buy-button {
    position: relative;
  }

  .btn-botton1 {
    position: relative;
    left: 4%;
  }

  .buy-button .btn-botton2 {
    width: 30%;
  }

  .main-dropdown {
    list-style: none;
  }

  .main-dropdown .marquee-link {
    text-decoration: none;
  }

  /* start */
  /* .buy-cat-button {
    cursor: pointer;
    font-weight: normal;
    font-style: normal;
    font-family: Montserrat;
    margin: 0 10px 0 0;
    outline: 0;
    padding: 8px 20px 9px 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
    color: #4f4f4f;
  } */

  .ui-state-default {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #f5f5f5;
    color: #4f4f4f;
  }

  /* 
  .sort {
      cursor: pointer;
      font-weight: normal;
      font-style: normal;
      margin: 0 10px 0 0;
      outline: 0;
      padding: 8px 7px 9px 7px;
      position: relative;
      text-decoration: none;
      color: #4F4F4F;
  } */
}

.sort-list select {
  font-family: Montserrat;
}

.sort-list p {
  font-family: Montserrat;
}

.dropdown {
  font-family: Montserrat;
}

/*   
.top-house {
  position: relative;
  bottom: 75%;
  background: red;
}

.top-house .top-time {
  background-color: rgba(51, 49, 49, 0.479);
  padding: 5px;
  float: left
}

.top-house .top-icon {
  background: green;
  float: right
} */

.fixed-none {
  display: none;
}

.overwrite-dropdown {
  width: 500px;
}
.overwrite-dropdown2 {
  width: 300px;
}

.subs-nav li a {
  text-decoration: none;
  color: #777;
  font-size: 12px;
}
.card-style {
  border-bottom: 3px solid #800080 !important;
}

.card-style img {
  object-fit: cover;
}

.btns button {
  font-size: 10px;
}
.btns .seach-icon {
  position: relative;
  right: 36%;
  top: 25%;
  /* left: 6%; */
  /* top: 23%; */
  /* right: 5%; */
}

.btns .sale-icon {
  position: relative;
  top: 9%;
  /* right: 39% */
  left: 20%;
}

.buy-button {
  padding-left: 7%;
  width: 20rem;
  position: relative;
}

.btn-botton1 {
  position: relative;
  left: 4%;
}

.buy-button .btn-botton2 {
  width: 30%;
}

.main-dropdown {
  list-style: none;
}

.main-dropdown .marquee-link {
  text-decoration: none;
}

.small-screen {
  display: none;
}

@media (max-width: 991px) {
  .buy-search-top {
    width: 100%;
    margin-bottom: 2%;
  }
  .map-top {
    margin-top: 0;
    padding-top: 0;
  }
  .hey-there {
    margin-top: -20px;
  }
  .buttons-horizontal {
    margin-bottom: 5%;
  }
  /* 
  .buy-cat-button {
    cursor: pointer;
    font-weight: normal;
    font-style: normal;
    outline: 0;
    padding: 4px 10px 5px 10px;
    font-family: Montserrat;
    position: relative;
    text-align: center;
    text-decoration: none;
    color: #4f4f4f;
  } */

  .ui-state-default {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #f5f5f5;
    color: #4f4f4f;
  }

  .top-buttons-sections form {
    width: 100px;
  }

  .top-buttons-sections .main-dropdown {
    width: 100%;
  }

  .map-section {
    display: none;
  }
  .map-top {
    text-align: center;
    margin: auto;
  }
  .scroll-hotel {
    text-align: center;
    margin: 0 auto;
  }
  .small-screen {
    display: block;
  }
  .big-screen {
    display: none;
  }
}

.size-bath {
  border: 1px solid #800080;
  margin-bottom: 10px;
  padding: 5px 20px;
  background: transparent;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
.size-bath:hover {
  color: #000;
}
/*.bedrooms-section {
  padding-bottom: 20px
}*/
.bedroom-div {
  padding-bottom: 10px;
}
.selected-size {
  background: #800080;
  color: white;
}

/* dropdown */
@media (min-width: 768px) {
  .dropdown-menu {
    margin-left: -20px;
  }
  .dropdown-menu li {
    position: relative !important;
  }
  .dropdown-menu .dropdown-submenu {
    display: none !important;
    position: absolute !important;
    left: 100% !important;
    top: -7px !important;
  }
  .dropdown-menu .dropdown-submenu-left {
    right: 100% !important;
    left: auto !important;
  }

  .dropdown-menu > li:hover > .dropdown-submenu {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .category-options {
    width: 81%;
    float: right;
  }

  /* .category-options .left-option {
    left: -60px;
  }
  .category-options .right-option {
    right: 0px;
  }
  .category-options .right-end-option {
    left: 40px;
  } */
  .category-options .right-option {
    font-size: 2vw;
    padding-left: 25px;
  }
  .category-options .right-end-option {
    font-size: 2vw;
  }
  .sort-section {
    float: right;
  }
  .dropdown-menu {
    margin-left: -20px;
  }

  .more-menu {
    margin-left: -90px;
  }

  .dropdown-menu li {
    position: relative !important;
  }
  .dropdown-menu .dropdown-submenu {
    display: none !important;
    position: absolute !important;
    top: -7px !important;
  }
  .dropdown-menu .dropdown-submenu-left {
    right: 100% !important;
    left: auto !important;
  }

  .dropdown-menu > li:hover > .dropdown-submenu {
    display: block !important;
  }
}
@media (min-width: 300px) and (max-width: 1200px) {
  .main-dropdown .card {
    position: absolute;
    width: 400px;
    z-index: 100;
    right: -179px;
  }
}
@media (min-width: 280px) and (max-width: 575px) {
  .buy-cat-button {
    font-size: 4vw;
    background: red;
  }
  .fixed-header {
    position: fixed;
    background: white;
    padding-top: 21px;
    z-index: 2;
    top: 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.61);
  }
  .buttons-horizontal {
    /* buy header fixed position */
  }

  .category-section {
    width: 100%;
    /* background: green */
  }

  .sort-list {
    /* background:green; */
    display: none !important;
  }

  .sort-section {
    width: 100%;
  }

  .main-premium {
    display: none !important;
  }

  .sort-text {
    width: 100%;
    padding-left: 20px;
    font-size: calc(0.75em + 1vmin);
  }

  .single-button {
    background: green;
  }

  .category-options .right-option {
    right: 0px;
    font-size: 3.1vw;
    padding-left: 10px;
  }
  .category-options .right-end-option {
    left: 20px;
    font-size: 3.1vw;
  }

  .card-body {
    text-align: left;
  }

  .scroll-hotel {
    padding: 0;
    margin: 0;
  }
  .product-card {
    padding: 0;
    margin: 0;
  }
  .hey-there {
    padding: 0;
  }
  .card {
    border-radius: 0;
  }
  .product-card .card img {
    width: 200%;
    height: 200px;
  }
}

@media (min-width: 280px) {
  .sort-section {
    float: right;
  }
  .main-dropdown .card {
    position: absolute;
    width: 400px;
    z-index: 100;
    right: -253px;
    top: 71px;
  }

  .product-card .card {
    width: 100% !important;
  }

  .product-card .card img {
    max-width: 100% !important;
    height: 200px;

    width: 36rem;
  }
}
@media (min-width: 414px) {
  .main-dropdown .card {
    position: absolute;
    width: 400px;
    z-index: 100;
    right: -257px;
    top: 52px;
  }
  /* .buttons-horizontal .buy-cat-button {
    font-size: 12px !important;
  } */
}

@media (max-width: 400px) {
  .main-dropdown .card {
    position: absolute;
    width: 19rem !important;
    z-index: 100;
    right: -193px !important;
    top: 52px;
  }
  /* .buttons-horizontal .buy-cat-button {
    font-size: 12px !important;
  } */
}

@media (min-width: 700px) and (max-width: 887px) {
  .main-dropdown .card {
    position: absolute;
    width: 400px;
    z-index: 100;
    right: -281px;
    top: 52px;
  }
}

@media (min-width: 779px) {
  .main-dropdown .card {
    position: absolute;
    width: 400px;
    z-index: 100;
    right: -100px;
    top: 52px;
  }
}

@media (min-width: 470px) and (max-width: 700px) {
  .main-dropdown .card {
    position: absolute;
    width: 400px;
    z-index: 100;
    right: -284px;
    top: 52px;
  }
}

.price-input {
  width: 90%;
  margin: auto;
  border: 1px solid #800080;
  border-radius: 3px;
}

.price-space-input {
  margin-top: 1%;
}

.kategori {
  text-align: center;
}

.kategori input:first-child {
  margin-bottom: 2%;
}

@media (max-width: 582px) {
  body {
    max-width: 100%;
  }
  .buy-search-top {
    width: 90%;
  }
  .main-dropdown {
    width: 100%;
  }
}

.search-product-list {
  width: 100%;
}

.dropdown-submenu li a {
  cursor: pointer;
}

.ratings-overview {
  margin-top: 5%;
}

.selected-agent-btn {
  margin-top: 2%;
}

.buy-cat-button {
  background: #fff !important;
  color: purple !important;
  font-size: 12px !important;
  border: 2px solid purple !important;

  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.buttons-horizontal .buy-cat-button :hover {
  /* background-color: purple !important; */
  border: 5px solid purple !important;
}

.price-needed {
  position: relative;
  right: 40px;
}
.price-needed li {
  list-style: none;
  padding: 5px;
}
.price-needed li a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.price-needed2 {
  position: relative;
  left: 150px;
}
.price-needed2 li {
  list-style: none;
  padding: 5px;
}
.price-needed2 li a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

@media (min-width: 778px) {
  #navigating-header {
    display: flex !important;
  }
  #navigating-header2 {
    display: none !important;
  }
  #sort-mobile {
    display: block;
  }
}

@media (max-width: 770px) {
  #main-premium {
    display: block !important;
  }

  #desktop-section {
    display: none;
  }
}

.desktop-main {
  display: none !important;
}
.sort-mobile {
  display: none;
}
.main-thing {
  display: none !important;
}
#modal-more {
  position: relative;
  width: auto;
  margin: 0.5rem;
  right: -43%;
  /* height: 100vh; */
  pointer-events: none;
}


.main-modal {
  margin-top: -5%;
  height: 800px;
}

/*Imoh media queries*/
@media (min-width: 1200px) {
  .scrollspy-example {
    position: relative;
    height: 300px;
    overflow: auto;
  }
  .show-share-mobile {
    display: none;
  }
  .hide-share {
    display: none;
  }
  .modal-text-mobile {
    display: none;
  }

  .main-modal .modal-content {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-radius: 0.3rem !important;
    outline: 0 !important;
    width: 71rem !important;
    right: 65% !important;
    margin: 0;
  }
  .swiper {
    display: none;
  }
  .mobile-modal {
    display: none;
  }
  .main-modal {
    margin-top: -5%;
    height: 800px;
    z-index: 100000 !important;
  }
  .modal-button-text {
    width: 200%;
  }
  .share-for-mobile {
    display: none;
  }
  .signuppro-firsts .signup-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    padding: 3% 1%;
    width: 18.5%;
    border-radius: 12px;
    background: #fff;
    margin: 0.5rem;
  }
  .show-mobile {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .scrollspy-example {
    position: relative;
    height: 300px;
    overflow: auto;
  }
  .show-share-mobile {
    display: none;
  }
  .hide-share {
    display: none;
  }
  .modal-text-mobile {
    display: none;
  }
  .main-modal .modal-content {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-radius: 0.3rem !important;
    outline: 0 !important;
    width: 60rem !important;
    right: 54% !important;
  }
  .swiper {
    display: none;
  }
  .mobile-modal {
    display: none;
  }
  .main-modal {
    margin-top: -5%;
    height: 800px;
    z-index: 100000 !important;
  }
}

@media (min-width: 893px) and (max-width: 991px) {
  .scrollspy-example {
    position: relative;
    height: 300px;
    overflow: auto;
  }
  .show-share-mobile {
    display: none;
  }
  .hide-share {
    display: none;
  }
  .modal-text-mobile {
    display: none;
  }
  .main-modal .modal-content {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-radius: 0.3rem !important;
    outline: 0 !important;
    width: 170% !important;
    right: 35% !important;
  }
  .swiper {
    display: none;
  }
  .mobile-modal {
    display: none;
  }
  .main-modal {
    margin-top: -5%;
    height: 800px;
    z-index: 100000 !important;
  }
}

.goclick {
  position: relative;
  z-index: 9;
}
@media (max-width: 600px) {
  .das-button {
    width: 50%; 
  }

  .btn-promotion{
    margin-bottom: 5%;
  }
  .main-logo {
    display: none;
  }
  .sort-section {
    /* it works here for mobile screen */
  }
  .category-options {
    /* width: 100%; */
  }

  .modal #modal-content .modal-image {
    height: auto !important;
    /* width: 200px !important; */
  }

  .scrollspy-example {
    position: relative;
    height: 600px;
    overflow: auto;
    margin-bottom: 50%;
  }

  #section-3 {
    margin-bottom: 40%;
  }
  .hide-share-for-mobile {
    display: none;
  }

  .signuppro-hide {
    display: none;
  }

  .mobile-text-size {
    width: 60%;
  }
  .mobile-text-sizes {
    width: 40%;
  }

  .hide-share-mobile {
    display: none;
  }
  .veiw-details {
    display: none;
  }
  /* .scroll-spy-final {
    background:red
  } */

  .hide-modal-display {
    display: none;
  }

  .nav-pills .nav-item {
    width: 33.3%;
  }

  .respond-me .hide-selected-img {
    display: none;
  }
  .hide-modal-image {
    display: none;
  }
  .home-single {
    display: none;
  }
  .image-responsive-selected {
    display: none;
  }
  .card-header {
    display: none;
  }
  /*mobile*/
  .main-modal .modal-content {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-radius: 0.3rem !important;
    outline: 0 !important;
    width: 106% !important;
    right: 3% !important;
    margin: 0;
  }
  .main-modal {
    margin-top: -10%;
    height: 900px;
  }
  .mySwiper {
    position: relative;
  }

  #example {
    position: absolute;
    top: 1px;
    z-index: 2222;
    float: right;
    padding: 5px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .icons-right {
    margin-left: 50%;
  }
}

@media (min-width: 601px) and (max-width: 891px) {
  .main-modal .modal-content {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    pointer-events: auto !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-radius: 0.3rem !important;
    outline: 0 !important;
    width: 100% !important;
    right: 1% !important;
  }
}

/* swiper slider */

.swiper {
  width: 100%;
  height: 80%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* height: 300px; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* pagination styling starts */

.paginationBttns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 20px; */
  /* position: absolute;
  right: 50%;
  top: 100%; */
}
.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid orange;
  cursor: pointer;
  color: purple;
}

.paginationBttns a:hover {
  color: white;
  background-color: orange;
}
.paginationActive a {
  color: white;
  background-color: orange;
}
.paginationDisabled {
  display: none;
}
/* pagination styling end */

@media (max-width: 798px) {
  #left-option {
    background: white !important;
    /* padding: 81px; */
    position: relative !important;
    left: -37% !important;
  }
}

@media (min-width: 601px) {
  .modal-icon-mobile {
    display: none;
  }
}

.sort-list {
  display: none;
}

.dropdown-item {
  cursor: pointer;
}

/* new card */
.card-start {
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.long-card {
  margin-bottom: 3%;
}

/* sign up pro */

.big-card {
  padding: 0;
  margin: 0;
  background: red;
}

.signuppro-first {
  height: 500px;
  position: relative;
  background-image: url("../../images/c.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(200, 100, 0, 0.5); /* Tint color */
  background-blend-mode: multiply;
}

/* .signuppro-first .signup-card {
  width: 21%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  margin: 2%;
  padding: 3% 0;
  border-radius: 2%;
  background:#fff
}

.signuppro-first .signup-card h2{
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 20px 0;
}

.signuppro-first .signup-card a{
  background:purple;
  padding:10px 40px;
  color:#fff;
  text-transform: capitalize;
} */

.signuppro-firsts {
  width: 100%;
}

.signuppro-firsts .signup-card h2 {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 20px 0;
}

.signuppro-firsts .signup-card a {
  background: purple;
  padding: 10px 40px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 12px;
}

.signuppro-firsts .signup-card p {
  margin-top: 15px;
}

.start-section {
  height: 500px;
  position: relative;
  margin-bottom: 15%;
}

.below h1 {
  margin-top: -5%;
  padding-bottom: 5%;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px #ccc;
  font-size: 40px;
}

.active-nav {
  background-color: purple !important;
  color: white !important;
  border-color: orange !important;
}

.signuppro-first,
.below {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 15%;
  top: 0;
  left: 0;
}
.below {
  z-index: 10;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .share-for-mobile {
    display: none;
  }
  .signuppro-firsts .signup-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    padding: 3% 1%;
    width: 18.5%;
    border-radius: 12px;
    background: #fff;
    margin: 0.3rem;
  }
  .start-section {
    margin-bottom: 30%;
  }
  .show-mobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .share-for-mobile {
    display: none;
  }

  .signuppro-firsts .signup-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    padding: 5% 1%;
    width: 45.5%;
    border-radius: 12px;
    background: #fff;
    margin: 1rem;
  }
  .start-section {
    height: 700px;
    position: relative;
    margin-bottom: 90%;
  }
  .show-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .signuppro-firsts {
    display: none;
  }

  .signuppro-first {
    background: #fff;
  }

  .start-section {
    height: 750px;
  }

  .show-mobile .button {
    width: 100%;
    margin-bottom: 5%;
    padding: 10px;
  }

  .show-mobile .button h3 {
    color: #333;
    font-weight: 700;
    font-size: 90%;
    width: 50%;
  }

  .show-mobile .button p {
    width: 60%;
    color: #333;
    float: left;
  }

  .show-mobile .button a {
    background: purple;
    text-align: center;
    padding: 10px 30px 10px 30px;
    color: #fff;
    border-radius: 5%;
    font-size: 16px;
    font-weight: 800;
  }

  .show-mobile .button-style a {
    /* padding:10px 30px 10px 30px */
  }

  .top-pro {
    margin-top: -15%;
    color: #fff;
    background: purple;
    padding: 40px;
    padding-left: 20px;
  }

  .top-pro ul li {
    color: #fff;
  }

  /* .signuppro-firsts .signup-card {
  
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    padding: 10% 1%;
    width:80%;
    border-radius: 12px;
    background: #fff; 
    margin: 3% auto;
  }
  .start-section {
    height: 1200px;
    position: relative;
    margin-bottom:1200px
  } */
  /* .mobile-signup {
    margin-bottom: 50%;
    background:green
  }*/
}

#accordion {
  width: 900px;
}

.card h3 span.fa {
  float: right;
}

.card h3 a {
  display: block;
}

#hero1 {
  background: url(../../../public/images/background.jpeg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
#hero2 {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/21555/Melissani-cave-natural.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
.hero,
.content {
  text-align: center;
  position: relative;
  width: 100%;
}

.inner {
  min-height: 600px;
  position: relative;
}

.hero .inner {
  background: rgba(0, 0, 0, 0.7)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC)
    repeat;
}

.copy {
  position: absolute;
  top: 50%;
  height: 10em;
  margin-top: -5em;
  width: 100%;
}

.hero h1,
.hero p {
  color: #fff;
}

.content h1,
.content p {
  color: #333;
}

.hero h1 {
  margin: 0px;
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  font-size: 32px;
  padding: 0px 20px;
}

.hero p {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  padding: 0px 20px;
}

.agent-link {
  cursor: pointer;
}

/* scrollspy */

.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: block !important;
}

.nav .nav-item {
  width: 33.3%;
  float: left;
  display: inline;
}

.nav .nav-item a {
  display: block;
  color: #000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

#section-3 {
  padding-bottom: 5%;
}

.agent-name {
  width: 60%;
}

.ratings {
  width: 40%;
}

/* .star-ratings{
  width: 300px !important;
} */
.ratings .star-ratings {
  width: 200px;
}

@media (max-width: 576px) {
  .ratings .star-ratings {
    width: 100% !important;
  }
}

/* new media query method */
/* @media (width <= 576px) {
  .star-ratings {
    width: 100%;
  }
} */

/* new media query method */
/* what this simply means is that  from 300px or less than 300px to 500px should take the style below  */
/* @media (300px < width <= 500px) {
  .star-ratings {
    width: 100px;
  }
} */


.navbar-dashboard-btn {
  color: #000;
}

.test:after {
  content: '\2807';
  font-size: 100px;
}

.property-inner {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.property-main {
  margin-top: 10%;
}

.btn-promotion {
  background: #800080;
  color: #fff;
}

.promote-heading{
  font-size: 14px;
}

.update-agent-btn {
  width: 47%;
}

.add-space {
  margin-bottom: 10%;
}



