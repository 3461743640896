.faqs {
    font-family: "Lato", sans-serif;
    width: 80%;
    padding: 50px;
    margin: 0 auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
  }
  
  .faq-section {
    max-width: 760px;
    margin: 0 auto;
  }
  
  .faqs header {
    margin-bottom: 70px;
  }
  
  .faqs header > p {
    font-size: 22px;
    color: #aeb9c6;
  }
  
  .faqs h2 {
    font-size: 52px;
    color: #000;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .faqs details {
    cursor: pointer;
  }
  
  .faqs h4 {
    width: 760px;
  }
  
  .faqs summary {
    font-size: 20px;
    color: #000;
    display: flex;
    justify-item: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  
  .faqs summary::marker {
    display: none;
    content: "";
  }
  
  .faqs span {
    margin-top: 4px;
    color: #000;
  }
  
  .faqs details > p {
    max-width: 650px;
    color: #aeb9c6;
    font-size: 18px;
    line-height: 155%;
    letter-spacing: 0.1px;
    margin-bottom: 40px;
    padding-left: 10px;
  }
  
  .faqs hr {
    color: #313133;
    margin-block: 20px;
  }