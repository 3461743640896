.saved-items-section {
    padding: 20px 5px 10px 5px;
    margin-right: auto;
    margin-left: auto;
}

.saved-top {
    clear: both;
    position: relative;
    table-layout: fixed;
    display: table;
    text-align: center;
    width: 100%;
    height: 200px;
    background-color: #800080;
    background-image: none;
    border: 0px solid #CCCCCC;
    box-sizing: border-box;
    margin: 0;
}

.saved-text {
    margin-top: 5%;
    padding-right: 15px;
    padding-left: 15px;
    font-family: Montserrat;
    color: #fff
}

.saved-item-img {
    border: 0px solid #000000;
    border-radius: 0px;
    box-sizing: border-box;
    padding: 0;
    display: block;
    width: 100%;
    height: auto;
    max-width: 801px;
    margin-left: auto;
    margin-right: auto;
}

.saved-card {
    margin-bottom: 5%
}

.saved-item-text {
    margin-top: 5%;
    margin-bottom: 5%
}

.saved-item-text p {
    color: #000;
    font-size: 13px;
    font-family: Montserrat;
    line-height: 31px;
    margin: 0;
    padding: 0;
}

.saved-item-text h3 {
    color: #800080;
    font-size: 27px;
    font-family: Montserrat;
    line-height: 28px;
    font-weight: 600;

}

.saved-item-text button {
    margin-top: 5%;
    text-transform: capitalize;
    background: transparent;
    border: none;
    color: #800080;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 600;
    line-height: 28px;
}

.saved-button-bottom {
    padding: 40px 15px 80px 15px;
    margin-right: auto;
    margin-left: auto;
    text-align: center
}

.saved-button-bottom button{
    background: #ffcc00;
    width: 222px;
    height: 39px;
    border: 1px solid #FFCC00;
    border-radius: 4px;
    background-image: none;
    color: #000000;
    font-family: Montserrat;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    padding: 1px 6px 1px 6px;
    text-align: center;
    -webkit-appearance: none;
    margin: 0;
    text-transform: capitalize;
}

.saved-button-bottom button:hover{
    background: transparent;
    border: 2px solid #ffcc00;
    transition: 0.4s
}

@media (max-width: 991px) {
    .saved-text {
        margin-top: 12%;
        font-family: Montserrat;
    }
    .display-4 {
        padding-top: 30px;
        font-size: 30px;
        font-family: Montserrat
    }
}

@media (min-width: 992px) {
    .saved-text {
        font-family: Montserrat;
    }
    .display-4 {
        padding-top: 70px;
        font-size: 30px;
        font-family: Montserrat
    }
}