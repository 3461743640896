
@media (min-width: 601px) {
  .scrollspy-example {
    position: relative !important;
    height: 300px !important;
    overflow: auto !important;
  }
  .scroling {
    overflow-y: scroll;
    height: 20vh;
    overflow-x: hidden;
  }
}
@media (max-width: 600px) {
  
}
@media (max-width: 991px) {
  .selected-img {
    width: auto !important;
  }
  .selected-agent-btn {
    margin-bottom: 5px;
    background-color: purple;
    color: #fff;
  }
  .inner-main {
    display: flex;
    justify-content: space-around;
    padding: 24px;
  }
}

/* .star-ratings .star-container .widget-svg {
  fill: purple !important;
}
.star-ratings .star-container .hovered {
  fill: red !important;
} */

@media (max-width: 890px) {
  
  /* // do something in this width range. */
  .respond-me {
    flex: 0 0 auto !important;
    width: 100%;
  }

  .image-responsive-selected img {
    width: 550px !important;
    height: 320px !important;
  }


  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: purple !important;
    border-bottom: 2px solid purple
  }

  .under-ul li a {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .overal-botton {
    display: block;
  }
}

.inner-main {
  display: flex;
  /* margin: 22px; */
  /* padding: 40px; */
  justify-content: space-around;
  padding: 24px;
}

/* @media only screen and (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }
} */

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  /* right: 44%; */
}

.navbar-example {
  padding-top: 44px;
}

.overal-botton {
  display: none;
}


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: purple !important;
  border-bottom: 3px solid purple;
  background-color: transparent;
}

.under-ul li a {
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.srolling-to {
  position: relative !important;
  height: 300px !important;
  /* overflow: auto !important; */
  overflow-x: hidden;
}



#mode-modal .modal-content {
  right: 0 !important;
}