@media (min-width: 992px) {
    .sell-section {
        /* padding: 200px 20px 170px 20px;
        margin-right: auto;
        margin-left: auto; */
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        background-color: transparent;
        background-image: "url(" + "../../images/apple.png" + ")";
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        border: 0px solid transparent;
        box-sizing: border-box;
        margin: 0;
    }

    .sell-top-section {
        position: relative;
        display: inline-block; 
    }

    .text {
        position: absolute;
        z-index: 999;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%; /* Adjust this value to move the positioned div up and down */
        text-align: center;
        width: 60%;
    }

      .text h2 {
        color: #fff;
        font-weight: 600;
        font-size: 43px;
        line-height: 64px;

      }

    .text-p {
        color: #fff;
        font-size: 20px;
    }

    .sell-banner {
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        height: 200px;
        background-color: #800080;
        background-image: none;
        border: 0px solid #CCCCCC;
        box-sizing: border-box;
        margin: 0;
    }

    .sell-property {
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        background-color: transparent;
        background-image: none;
        border: 0px solid #CCCCCC;
        box-sizing: border-box;
        margin: 0;
        box-sizing: border-box;
        padding: 60px 100px 50px 100px;
        margin-right: auto;
        margin-left: auto;
    }

    .sell-property-text {
        width: 70%;
        text-align: center;
        margin-top: 5%
    }


    .sell-property-text h3 {
        color: #800080;
        width: 100%;
        padding-bottom: 10px;
        margin: auto
    }

    .sell-property-text button {
        height: 45px;
        width: 142px;
        display: inline-block;

        box-sizing: border-box;
        line-height: 43px;
        text-decoration: none;
        vertical-align: top;
        border: 0px solid #FFFFFF;
        border-radius: 4px;
        background-color: #FFCC00;
        background-image: none;
        color: #000000;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        padding: 1px 6px 1px 6px;
        text-align: center;
        -webkit-appearance: none;
        margin: 0;
    }

    .sell-property-text button:hover {
        background-color: #FFFACD;
    -webkit-transition: background-color 5ms linear 0ms;
    transition: background-color 5ms linear 0ms;
    }

    .sell-text {
        margin-top: 5%;
        padding-right: 15px;
        padding-left: 15px;
        color: #fff;
        width: 100%;
        margin-left: 0 auto;
        margin-right: 0 auto;
    }

    .sell-text p {
        line-height: 25px;
        font-size: 20px;
        font-weight: 500;
    }

    .card-left {
        background-color: transparent;
        background-image: none;
        border: 0px solid #FFFFFF;
        border-radius: 0px;
        width: 50%;
        text-align: center;
    }

    .image3 {
        border: 0px solid #000000;
        border-radius: 0px;
        box-sizing: border-box;
        padding: 0;
        display: block;
        width: 100%;
        height: auto;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .sell-property-text-second {
        background: red;
        margin-top: 10%
    }

    .second {
        margin-left: 15%;
    }

    /* FAQ SECTION */
    .frequently-asked-questions {
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .faq-card header {

    }

    .faq-card header .question-title-button {
        background: transparent;
        padding: 10px 0;
        border: none;
        width: 60%;
    }

    .faq-card header .question-title {
        float: left;
        font-size: 18px;
    }

    .faq-card header .plus-icon {
        background: transparent;
        border: none;
        float: right;
        color: #000;
        font-size: 19px;
        font-weight: 900;
    }

    .question-body {
        background: #ccc;
        padding: 20px 20px;
        width: 60%;
        margin: auto;
        border-radius: 0 0 10px 10px ;
    }

}

/* accordion */



@media (max-width: 991px) {
    .sell-section {
        /* padding: 200px 20px 170px 20px;
        margin-right: auto;
        margin-left: auto; */
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        background-color: transparent;
        background-image: "url(" + "../../images/apple.png" + ")";
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        border: 0px solid transparent;
        box-sizing: border-box;
        margin: 0;
    }

    .sell-top-section {
        position: relative;
        display: inline-block;
        height: 400px;
    }

    .sell-top-section img{
        height: 400px;
        width: 400px;
    }

    .text {
        position: absolute;
        z-index: 999;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 40%; /* Adjust this value to move the positioned div up and down */
        text-align: center;
        width: 100%;
    }

    .text h2 {
        color: #fff;
        font-weight: 600;
        font-size: 43px;
        line-height: 44px;
    }

    .text-p {
        color: #fff;
        font-size: 25px;
    }

    .sell-banner {
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        height: 400px;
        background-color: #800080;
        background-image: none;
        border: 0px solid #CCCCCC;
        box-sizing: border-box;
        margin: 0;
    }

    .sell-property {
        clear: both;
        position: relative;
        table-layout: fixed;
        display: table;
        text-align: center;
        width: 100%;
        background-color: transparent;
        background-image: none;
        border: 0px solid #CCCCCC;
        box-sizing: border-box;
        margin: 0;
        box-sizing: border-box;
        padding: 0px 50px 50px 50px;
        margin-right: auto;
        margin-left: auto;
    }

    .sell-property-text {
        width: 100%;
        text-align: center;
        margin-top: 5%
    }


    .sell-property-text h3 {
        color: #800080;
        width: 100%;
        margin: auto
    }

    .sell-property-text button {
        height: 45px;
        width: 142px;
        display: inline-block;

        box-sizing: border-box;
        line-height: 43px;
        text-decoration: none;
        vertical-align: top;
        border: 0px solid #FFFFFF;
        border-radius: 4px;
        background-color: #FFCC00;
        background-image: none;
        color: #000000;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        padding: 1px 6px 1px 6px;
        text-align: center;
        -webkit-appearance: none;
        margin: 0;
    }

    .sell-property-text button:hover {
        background-color: #FFFACD;
        -webkit-transition: background-color 5ms linear 0ms;
        transition: background-color 5ms linear 0ms;
    }

    .sell-property-card-top {
        padding-top: 60px
    }

    .sell-text {
        margin-top: 15%;
        padding-right: 15px;
        padding-left: 15px;
        color: #fff;
        width: 100%;
        margin-left: 0 auto;
        margin-right: 0 auto;
    }

    .sell-text p {
        line-height: 25px;
        font-size: 17px;
        font-weight: 500;
    }

    .card-left {
        background-color: transparent;
        background-image: none;
        border: 0px solid #FFFFFF;
        border-radius: 0px;
        width: 100%;
        text-align: center;
    }

    .image-right {
        display: none
    }

    .image3 {
        border: 0px solid #000000;
        border-radius: 0px;
        box-sizing: border-box;
        padding: 0;
        display: block;
        width: 100%;
        height: auto;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .sell-property-text .second {
        background: red;
    }


        /* FAQ SECTION */
        .frequently-asked-questions {
            padding-top: 5%;
            padding-bottom: 5%;
        }
    
        .faq-card header {
    
        }
    
        .faq-card header .question-title-button {
            background: transparent;
            padding: 10px 0;
            border: none;
            width: 90%;
        }
    
        .faq-card header .question-title {
            float: left;
            font-size: 18px;
        }
    
        .faq-card header .plus-icon {
            background: transparent;
            border: none;
            float: right;
            color: #000;
            font-size: 19px;
            font-weight: 900;
        }
    
        .question-body {
            background: #ccc;
            padding: 20px 20px;
            width: 90%;
            margin: auto;
            border-radius: 0 0 10px 10px ;
        }
    

}

/* accordion */