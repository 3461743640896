table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .admin-modal-delete {
    width: 79%;
    position: absolute;
    left: 100px;
    top: 168px;
  }